import { HttpCMSAdapter } from "@sia/style-guide";
import httpClient from "common/HttpClient";

const headers = {
  headers: {
    "api-key": process.env.REACT_APP_CMS_API_KEY,
  },
};
const cmsURL = process.env.REACT_APP_CMS_URL;

const create = () => {
  return new HttpCMSAdapter(httpClient, headers, cmsURL);
};

const cmsAdapter = create();
export default cmsAdapter;
