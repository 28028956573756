// import httpClientAuthorizer from "adapters/IDPAdapter/HttpClientAuthorizer";
// import keycloakIDPAdapter from "../IDPAdapter/KeycloakIDPAdapter";
// import IDPAdapter from "../IDPAdapter/IDPAdapter";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export default class HttpPGAdapter {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  get(relativePath, data, onSuccess, onError) {
    this.fetch("get", relativePath, data, onSuccess, onError);
  }

  fetch(method, relativePath, data, onSuccess, onError) {
    const url = `${apiBaseUrl}/${relativePath}`;
    this.httpClient.request({
      method: method,
      url: url,
      data: data,
      onSuccess: onSuccess,
      onError: onError,
    });
  }

  getInitialFilters(shortLocale, onSuccess, onError) {
    const data = { lang: this.getLocale(shortLocale) };
    this.get("directory/filter/values", data, onSuccess, onError);
  }

  getIndividualMembers(data, onSuccess, onError) {
    this.get("directory/members", data, onSuccess, onError);
  }

  getCorporateMembers(data, onSuccess, onError) {
    this.get("directory/members", data, onSuccess, onError);
  }

  getMemberDetails(data, onSuccess, onError) {
    this.get("directory/member/details", data, onSuccess, onError);
  }

  getLocale(shortLocale) {
    const locales = {
      de: "de_DE",
      en: "en_US",
      fr: "fr_CH",
      it: "it_IT",
    };
    return locales[shortLocale];
  }
}
