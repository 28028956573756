const useContentMethods = (
  cmsAdapter,
  cachedValues,
  setCachedValues,
  languageKey,
  history,
  setContentType,
  setSiaContentProps,
  setIsLoading,
  node_id,
  alias,
  isEventOccurrence
) => {
  const setContent = (content) => {
    const { errors, content_type, ...data } = content;
    if (errors?.length > 0) {
      console.error(`Errors in ${data?.title}: `, errors);
      history.push(`/${languageKey}/error`);
      return;
    }
    setContentType(content_type);
    if (content_type === "sia_page") {
      const { title, page_top, page_bottom } = data;
      setSiaContentProps({ title, page_top, page_bottom });
    } else if (content_type === "sektion_page") {
      const { title, page_top, page_bottom, section } = data;
      setSiaContentProps({ title, page_top, page_bottom, section });
    } else if (content_type === "speaker") {
      setSiaContentProps(data);
    } else {
      setSiaContentProps({ item: data });
    }
    if (node_id > 0) {
      setCachedValues({ ...cachedValues, [`content-${node_id}`]: content });
    }
    setIsLoading(false);
  };
  const getContentByHash = () => {
    cmsAdapter.fetchNodeByHash(languageKey, alias, setContent);
  };
  const getContentByNodeID = () => {
    const key = `content-${node_id}`;
    const cachedValue = cachedValues[key];
    if (cachedValue) {
      setContent(cachedValue);
    } else {
      if (isEventOccurrence) {
        cmsAdapter.fetchEvent(languageKey, alias, setContent);
      } else {
        cmsAdapter.fetchNode(languageKey, node_id, setContent);
      }
    }
  };

  return { getContentByHash, getContentByNodeID };
};

export default useContentMethods;
