import React, { useCallback, useContext, useEffect, useState } from "react";
import Page from "../Page";
import JobsContentNavigation from "./JobsContentNavigation";
import { AppContent, Link, JobPreviews, TagsGroup, Pagination, Content, MultiselectDropdown, safeStateChanger, Dropdown } from "@sia/style-guide";
import { useTranslator } from "common/Translation/Translator";
import paginator from "common/Paginator";
import "./JobsFilters.scss";
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";
import webPlatformAdapter from "../../common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import GlobalContext from "contexts/GlobalContext";
import isGoogleBot from "common/GoogleBots";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { useNavigator } from "common/Navigator";

const initialOpenMultiselects = {
  job: false,
  region: false,
  contractType: false,
};

const JobOffersPage = ({ jobOffersFilters, setJobOffersFilters, jobOffersPageArgs, setJobOffersPageArgs, isSubscribedToJobs }) => {
  const translate = useTranslator();
  const navigate = useNavigator();
  const [jobPreviewsItemsOriginal, setJobPreviewsItemsOriginal] = useState([]);
  const [jobPreviewsItems, setJobPreviewsItems] = useState([]);
  const [page, setPage] = useState(1);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [openMultiselects, setOpenMultiselects] = useState(initialOpenMultiselects);
  const [multiselectItems, setMultiselectItems] = useState({
    jobs: [],
    regions: [],
    contractTypes: [],
  });
  const JOBS_URL = process.env.REACT_APP_WEB_PLATFORM_JOBS_URL;

  const { language: currentLanguage, user } = useContext(GlobalContext);
  const [sortCriteria, setSortCrieteria] = useState({
    jobs: jobOffersFilters.jobs,
    regions: jobOffersFilters.regions,
    contractTypes: jobOffersFilters.contractTypes,
  });

  const [currentFilters] = useState(jobOffersFilters);
  const [chosenSort, setChosenSort] = useState(jobOffersPageArgs.chosenSort);

  const [shouldFetch, setShouldFetch] = useState(true);

  const sortDropdownItems = [
    { id: "newest", label: translate("jobs.search.sort.newest") },
    { id: "oldest", label: translate("jobs.search.sort.oldest") },
    { id: "company", label: translate("jobs.search.sort.company") },
    { id: "job", label: translate("jobs.search.sort.job") },
    { id: "region", label: translate("jobs.search.sort.region") },
    { id: "contractType", label: translate("jobs.search.sort.workload") },
  ];

  const location = useLocation();
  const userAgent = navigator.userAgent;
  const baseUrl = location.pathname.replace(/\/$/g, "");
  const pathFactory = usePathFactory();

  const constructJobPreviewItems = useCallback(
    (data) => {
      const items = Object.entries(data).map(([key, value]) => {
        return {
          id: key,
          contractTypes: value[1]["workloads"],
          regions: value[2]["regions"],
          title: value[0]["title"],
          name: value[0]["company"],
          date: value[0]["start_date"],
          logo: value[0]["logo"],
          url: `offers/${key}`,
          translate: translate,
          tags: <TagsGroup items={constructTagItems(value[1]["workloads"], value[2]["regions"])} />,
        };
      });

      return items;
    },
    [translate]
  );

  const constructTagItems = (workLoads, regions) => {
    const workLoadItems = workLoads.map((wl) => ({
      label: wl.workload,
      type: "primary",
    }));

    const regionItems = regions.map((region) => ({
      label: region.region,
      type: "quaternary",
    }));

    return workLoadItems.concat(regionItems);
  };

  const initlizeFilters = useCallback(
    (language) => {
      const getActiveFilters = (filter, item) => {
        let temp = currentFilters[filter].filter((e) => e.id === item.id);
        return temp.length > 0;
      };
      // const language = i18n.language;
      webPlatformAdapter.getJobTypes(language, (response) => {
        setMultiselectItems((state) => ({
          ...state,
          jobs: response.data.map((item) => ({
            id: item.id,
            label: item.name,
            active: getActiveFilters("jobs", item),
          })),
        }));
      });

      webPlatformAdapter.getJobRegions(language, (response) => {
        setMultiselectItems((state) => ({
          ...state,
          regions: response.data.map((item) => ({
            id: item.id,
            label: item.name,
            active: getActiveFilters("regions", item),
          })),
        }));
      });

      webPlatformAdapter.getJobWorkload(language, (response) => {
        setMultiselectItems((state) => ({
          ...state,
          contractTypes: response.data.map((item) => ({
            id: item.id,
            label: item.name,
            active: getActiveFilters("contractTypes", item),
          })),
        }));
      });
    },
    [currentFilters]
  );

  const onClickSubscribe = () => {
    navigate("account/services");
  };

  const onPaginate = (targetPage, perPageLimit) => {
    setPage(targetPage);
    setPerPageLimit(perPageLimit);
    setPaginatedItems(paginator.paginateItems(jobPreviewsItems, targetPage, perPageLimit));
  };

  const onToggleMultiselect = (name, newValue) => {
    updateOtherFilters(jobPreviewsItems);
    setOpenMultiselects(safeStateChanger.changeValue(initialOpenMultiselects, name, newValue));
  };

  const onClickMultiselectItem = (name, updatedItems) => {
    const sortCriterion = updatedItems.filter((e) => e.active);
    setSortCrieteria((state) => ({ ...state, [name]: sortCriterion }));
    setMultiselectItems(safeStateChanger.changeValue(multiselectItems, name, updatedItems));
  };

  const updateOtherFilters = (filteredItems) => {
    const filters = ["jobs", "regions", "contractTypes"];

    let currentAvailableJobsTitle = filteredItems.map((e) => e.title);
    currentAvailableJobsTitle = [...new Set(currentAvailableJobsTitle)];

    let currentAvailableRegions = filteredItems.flatMap((e) => e.regions.map((region) => region.id));
    currentAvailableRegions = [...new Set(currentAvailableRegions)];

    let currentAvailableWorkloads = filteredItems.flatMap((e) => e.contractTypes.map((contract) => contract.id));
    currentAvailableWorkloads = [...new Set(currentAvailableWorkloads)];

    let currentAvailableFilter = {
      jobs: currentAvailableJobsTitle,
      regions: currentAvailableRegions,
      contractTypes: currentAvailableWorkloads,
    };

    const stateObject = filters.reduce((acc, filter) => {
      acc[filter] = multiselectItems[filter].map((item) => {
        return {
          ...item,
          disabled: filter === "jobs" ? !currentAvailableFilter[filter].includes(item.label) : !currentAvailableFilter[filter].includes(item.id),
        };
      });
      return acc;
    }, {});

    setMultiselectItems((state) => ({
      ...state,
      ...stateObject,
    }));
  };

  const sortList = useCallback(
    (list, item = chosenSort) => {
      const listCopy = [...list];
      const sortedListCopy = listCopy.sort((a, b) => {
        switch (item.id) {
          case "job":
            return a.title.localeCompare(b.title);
          case "region":
            return a.regions[0].region.localeCompare(b.regions[0].region);
          case "contractType":
            return a.contractTypes[0].workload.localeCompare(b.contractTypes[0].workload);
          case "company":
            return a.name.localeCompare(b.name);
          case "newest":
            return new Date(b.date) - new Date(a.date);
          case "oldest":
            return new Date(a.date) - new Date(b.date);
          default:
            return 0;
        }
      });
      return sortedListCopy;
    },
    [chosenSort]
  );

  const toggleChosenSort = (item) => {
    setJobOffersPageArgs((state) => ({ ...state, chosenSort: item }));
    setChosenSort(item);
    const sortedJobPreviewItems = sortList(jobPreviewsItems, item);
    setJobPreviewsItems(sortedJobPreviewItems);
    setPage(1);
    setPaginatedItems(paginator.paginateItems(sortedJobPreviewItems, 1, perPageLimit));
  };

  useEffect(() => {
    if (currentLanguage && shouldFetch) {
      webPlatformAdapter.getJobOffers(currentLanguage, (response) => {
        setShouldFetch(false);
        const { data } = response;
        let structuredJobPreviewItems = constructJobPreviewItems(data);
        structuredJobPreviewItems = sortList(structuredJobPreviewItems);
        setJobPreviewsItemsOriginal(structuredJobPreviewItems);
        initlizeFilters(currentLanguage);
      });
    }
  }, [translate, currentLanguage, constructJobPreviewItems, sortList, initlizeFilters, shouldFetch]);

  useEffect(() => {
    const isSearchFilterEmpty = () => Object.values(sortCriteria).every((e) => e === null || e.length === 0);

    let filteredItems = null;

    filteredItems = jobPreviewsItemsOriginal.filter((item) => {
      const isTitleMatched = sortCriteria.jobs.length ? sortCriteria.jobs.some((job) => job.label === item.title) : true;

      const isRegionMatched = sortCriteria.regions.length
        ? sortCriteria.regions.some((e) => item.regions.some((region) => region.region === e.label))
        : true;

      const isJobTypeMatched = sortCriteria.contractTypes.length
        ? sortCriteria.contractTypes?.some((e) => item.contractTypes.some((contract) => contract.workload === e.label))
        : true;

      if (isSearchFilterEmpty()) {
        return true;
      }
      return isTitleMatched && isRegionMatched && isJobTypeMatched;
    });
    setJobOffersFilters({ ...sortCriteria });
    setPage(1);
    filteredItems = sortList(filteredItems, chosenSort);
    setJobPreviewsItems(filteredItems);
    if (isGoogleBot(navigator.userAgent)) {
      setPaginatedItems(paginator.paginateItems(filteredItems, 1, filteredItems.length));
    } else {
      setPaginatedItems(paginator.paginateItems(filteredItems, 1, perPageLimit));
    }
  }, [sortCriteria, jobPreviewsItemsOriginal, sortList, setJobOffersFilters, perPageLimit, chosenSort]);

  if (isGoogleBot(userAgent) && baseUrl.endsWith("jobs")) {
    return <Redirect to={pathFactory.create("jobs/0")} />;
  }
  return (
    <Page
      contentNavigation={isSubscribedToJobs ? <JobsContentNavigation isSubscribedToJobs={isSubscribedToJobs} /> : null}
      className={"job-offers"}
      title="jobs.name"
    >
      <AppContent>
        <p>{translate("jobs.offers.description.person")}</p>
        <p>{translate("jobs.offers.description.corporate")}</p>
        <div className="jobs-link">
          <Link target={`/${currentLanguage}/${JOBS_URL}`} withLeadIcon>
            {translate("jobs.online.terms")}
          </Link>
        </div>
        {!isSubscribedToJobs && user !== null && user.role === "sia_role_member_corporate" ? (
          <>
            <p></p>
            <p>{translate("jobs.offers.subscription.question")}</p>
            <Link target={onClickSubscribe} withLeadIcon>
              {translate("jobs.offers.subscription.link")}
            </Link>
          </>
        ) : null}
      </AppContent>
      <div className={"jobs-filters"}>
        <div className={"bx--grid reset-spacings filters"}>
          <div className={"bx--row"}>
            <div className={"bx--col-lg-8 bx--col-md-8 bx--col-sm-4"}>
              <MultiselectDropdown
                id={"job"}
                label={translate("jobs.search.filter.job")}
                isOpen={openMultiselects.job}
                items={multiselectItems.jobs}
                toggle={(newValue) => onToggleMultiselect("job", newValue)}
                onClickItem={(event, items, updatedItems) => {
                  onClickMultiselectItem("jobs", updatedItems);
                }}
              />
            </div>
            <div className={"bx--col-lg-4 bx--col-md-4 bx--col-sm-4 region"}>
              <MultiselectDropdown
                id={"region"}
                label={translate("jobs.search.filter.region")}
                isOpen={openMultiselects.region}
                items={multiselectItems.regions}
                toggle={(newValue) => onToggleMultiselect("region", newValue)}
                onClickItem={(event, items, updatedItems) => {
                  onClickMultiselectItem("regions", updatedItems);
                }}
              />
            </div>
            <div className={"bx--col-lg-4 bx--col-md-4 bx--col-sm-4 contract-type"}>
              <MultiselectDropdown
                id={"contractType"}
                label={translate("jobs.search.filter.workload")}
                isOpen={openMultiselects.contractType}
                items={multiselectItems.contractTypes}
                toggle={(newValue) => onToggleMultiselect("contractType", newValue)}
                onClickItem={(event, items, updatedItems) => {
                  onClickMultiselectItem("contractTypes", updatedItems);
                }}
              />
            </div>
          </div>
        </div>
        <Dropdown
          inline={true}
          id="sort"
          className={"sort"}
          items={sortDropdownItems}
          label={translate("sort.by")}
          value={jobOffersPageArgs.chosenSort}
          onChange={(event, item) => toggleChosenSort(item)}
        />
      </div>
      <JobPreviews items={paginatedItems} elementToRender={RouterLink} />
      <Content width={"full"}>
        {isGoogleBot(userAgent) ? null : <Pagination items={jobPreviewsItems} onChange={onPaginate} translate={translate} page={page} />}
      </Content>
    </Page>
  );
};

export default JobOffersPage;
