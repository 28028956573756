import httpClient from "common/HttpClient";
import httpClientAuthorizer from "./IDPAdapter/HttpClientAuthorizer";
import keycloakIDPAdapter from "common/Adapters/IDPAdapter/KeycloakIDPAdapter";

const apiUrl = process.env.REACT_APP_ERP_API_URL;
const newApiUrl = process.env.REACT_APP_NEW_ERP_API_URL;

class ERPAdapter {
  request(method, endpoint, data, onSuccess, onError) {
    keycloakIDPAdapter
      .getClient()
      .updateToken(0)
      .then((result) => {
        const url = `${apiUrl}/${endpoint}`;
        const config = httpClientAuthorizer.authorizeRequestConfig({});
        httpClient.request({
          method: method,
          url: url,
          data: data,
          onSuccess: onSuccess,
          onError: onError,
          config: config,
        });
      });
  }

  requestWithDelay(method, endpoint, data, onSuccess, onError, delay) {
    keycloakIDPAdapter
      .getClient()
      .updateToken(0)
      .then((result) => {
        const url = `${apiUrl}/${endpoint}`;
        const config = httpClientAuthorizer.authorizeRequestConfig({});
        httpClient.requestWithDelay({
          method: method,
          url: url,
          data: data,
          config: config,
          onSuccess: onSuccess,
          onError: onError,
          ignoreError: false,
          delay: delay,
        });
      });
  }

  fetch(method, endpoint, data, onSuccess, onError) {
    keycloakIDPAdapter
      .getClient()
      .updateToken(0)
      .then((result) => {
        const url = `${newApiUrl}/${endpoint}`;
        const config = httpClientAuthorizer.authorizeRequestConfig({});
        httpClient.request({
          method: method,
          url: url,
          data: data,
          onSuccess: onSuccess,
          onError: onError,
          config: config,
        });
      });
  }

  fetchWithDelay(method, endpoint, data, onSuccess, onError, delay) {
    keycloakIDPAdapter
      .getClient()
      .updateToken(0)
      .then((result) => {
        const url = `${newApiUrl}/${endpoint}`;
        const config = httpClientAuthorizer.authorizeRequestConfig({});
        httpClient.requestWithDelay({
          method: method,
          url: url,
          data: data,
          config: config,
          onSuccess: onSuccess,
          onError: onError,
          ignoreError: false,
          delay: delay,
        });
      });
  }
}

const erpAdapter = new ERPAdapter();
export default erpAdapter;
