import Page from "pages/Page";
import { Content, TagsGroup } from "@sia/style-guide";
import { useParams } from "react-router-dom";
import MemberDetailsHeader from "./MemberDetailsHeader";
import "./MemberDetailsPage.scss";
import { useTranslator } from "common/Translation/Translator";
import { ReferenceGalleryItemPreviews, JobPreviews } from "@sia/style-guide";
import Address from "../Fragments/Address";
import InfoList from "../Fragments/InfoList";
import ExtraInfo from "../Fragments/ExtraInfo";
import { Link as RouterLink } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "contexts/GlobalContext";
import pgAdapter from "common/Adapters/PGAdapter/PGAdapter";
import InfoListWithExtras from "../Fragments/InfoListWithExtras";
import InfoListWithLinks from "../Fragments/infoListWithLinks";
import FragmentTitle from "../Fragments/FragmentTitle";
import Tag from "@sia/style-guide/dist/components/Tag/Tag";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";
import { useNavigator } from "common/Navigator";
import { fileLoader } from "@sia/style-guide";

const MemberDetailsPage = ({ setSelectedFilters, setGlobalWorkBranches }) => {
  const translate = useTranslator();
  const navigate = useNavigator();
  let { id } = useParams();
  const [member, setMember] = useState({
    member_type: "",
    logo: "",
    iscompany: null,
    phone: "",
    email: "",
    website: "",
    indivisualMembers: [],
    professional_groups: {
      memberships: [],
      roles: [],
    },
    sections: {
      memberships: [],
      roles: [],
    },
    commissions: [],
    work_specialisations: [],
    work_branches: [],
    infos: [],
    address: [],
    reference_objects: [],
    jobs: [],
    xing_profile: "",
    linkedin_profile: "",
    self_description: "",
    founders: [],
    specialisation_details: {
      specialisations: [],
    },
    is_sia_specialist: null,
  });
  const logoRef = useRef({ width: 0, height: 0 });
  const [logoWrapperStyle, setLogoWrapperStyle] = useState(null);
  const [logoStyle, setLogoStyle] = useState(null);
  const { language } = useContext(GlobalContext);

  const constructTagItems = (workLoads, regions) => {
    const workLoadItems = workLoads.map((wl) => ({
      label: wl.workload,
      type: "primary",
    }));

    const regionItems = regions.map((region) => ({
      label: region.region,
      type: "quaternary",
    }));

    return workLoadItems.concat(regionItems);
  };

  useEffect(() => {
    if (language && id) {
      pgAdapter.getMemberDetails(
        {
          lang: pgAdapter.getLocale(language),
          hashed_member_id: id,
        },
        (res) => {
          let { data } = res;
          // let address = [];

          // for (let index = 1; index < 10; index++) {
          //   const address_line = data[`address_line_${index}`];
          //   data[`address_line_${index}`] && address.push(address_line);
          // }

          data.address = data.full_str.split(";");

          data.reference_objects = data.reference_objects || [];

          if (data.reference_objects) {
            data.reference_objects = data.reference_objects.map((e) => ({
              ...e,
              cantons: e.states.map((e) => e.computed_name),
              location: e.city,
              title: { lang: language, name: e.title },
              company: e.corporate,
              categories: e.categories.map((e) => e.name),
              image: e.thumbnail_picture,
              url: `/${language}/reference-gallery/search/project/${e.id}`,
            }));
          }

          if (data.jobs) {
            data.jobs = Object.entries(data.jobs).map(([key, value]) => ({
              id: key,
              contractTypes: value[1]["workloads"],
              regions: value[2]["regions"],
              title: value[0]["title"],
              name: value[0]["company"],
              date: value[0]["start_date"],
              logo: value[0]["logo"],
              url: `/${language}/jobs/offers/${key}`,
              translate: translate,
              tags: <TagsGroup items={constructTagItems(value[1]["workloads"], value[2]["regions"])} />,
            }));
          }

          if (data.commissions) {
            data.commissions = data.commissions.map((commission) => ({ ...commission, name: commission.code }));
          }
          if (data.founders) {
            data.founders = data.founders.filter((e) => e);
          }
          if (data.self_description === "None") {
            data.self_description = "";
          }
          setMember(data);
        }
      );
    }
  }, [id, language, translate]);

  useEffect(() => {
    let image = logoRef.current;
    if (member.logo) {
      let { height, width } = image;
      if (width > height) {
        setLogoWrapperStyle({
          paddingTop: "81px",
          paddingBottom: "81px",
        });
      } else if (height > width) {
        setLogoWrapperStyle({
          paddingRight: "61px",
          paddingLeft: "61px",
        });
      } else {
        setLogoWrapperStyle({
          padding: "46px",
        });
        setLogoStyle({ width: "151px", height: "151px" });
      }
    }
  }, [member.logo]);

  const callbackFactory = (property_name) => (item) => {
    let initialSelectedFilterState = {
      name: "",
      genders: [],
      cities: [],
      states: [],
      countries: [],
      member_types: [],
      sections: [],
      professional_groups: [],
      work_specialisations: [],
      work_branches: [],
      sia_commissions: [],
      has_ref_objects: false,
      has_jobs: false,
      is_specialist: false,
      specialists: [],
    };
    setSelectedFilters({ ...initialSelectedFilterState, [property_name]: [item.id] });
    navigate("directory");
  };

  return (
    <Page title={"directory.name"}>
      <Content width={"full"}>
        <div className="bx--grid reset-spacings CompanyPage">
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 MemberDetailsHeader-Backbutton">
              <BackButton onClick={() => navigate("directory")} />
            </div>
          </div>
          <div className="bx--row CompanyPage-Row">
            {member.logo && (
              <div className="bx--col-lg-4 bx--col-md-8 bx--col-sm-4">
                <div className="Logo-Wrapper sia-margin-02-right" style={logoWrapperStyle}>
                  <img alt="Logo" style={logoStyle} ref={logoRef} src={fileLoader.createBase64ImageSrc(member.logo)} className="Corporate-Logo" />
                </div>
              </div>
            )}

            <div className="bx--col-lg-12 bx--col-md-8 bx--col-sm-4">
              <MemberDetailsHeader {...member} onTagClick={callbackFactory("member_types")} />
              <div className="bx--row bx--grid reset-spacings ">
                <div className="bx--col-lg-7 bx--col-md-4 bx--col-sm-4 CompanyPage-Col">
                  <div className="sia-margin-04-right">
                    <Address
                      iscompany={member.iscompany}
                      address={member.address}
                      phone={member.phone}
                      mobile={member.mobile}
                      email={member.email}
                      website={member.website}
                      xing={member.xing_profile}
                      linkedin={member.linkedin_profile}
                      title={translate("directory.member.info.label.address")}
                    />
                  </div>
                </div>
                <div id="Directory-Right-col" className="bx--col-lg-7 bx--col-md-4 bx--col-sm-4 CompanyPage-Col">
                  <div className="sia-padding-02-right">
                    {member.iscompany ? (
                      <>
                        <InfoListWithLinks
                          listItems={member.founders}
                          listTitle={translate("directory.member.info.label.senior-member")}
                          language={language}
                        />
                        <InfoList
                          listItems={member.work_specialisations}
                          listTitle={translate("directory.member.info.label.activities")}
                          onTagClick={callbackFactory("work_specialisations")}
                        />
                        <InfoList
                          listItems={member.work_branches}
                          listTitle={translate("directory.member.info.label.branches")}
                          onTagClick={callbackFactory("work_branches")}
                          translate={translate}
                        />
                      </>
                    ) : (
                      <>
                        <InfoListWithLinks
                          listItems={member.member_of}
                          listTitle={translate("directory.member.info.label.corporate-membership")}
                          language={language}
                        />
                        <InfoListWithExtras
                          listItems={member.professional_groups.memberships}
                          listTitle={translate("directory.member.info.label.beruf-group")}
                          extras={member.professional_groups.roles}
                          onTagClick={callbackFactory("professional_groups")}
                          type={"quinary"}
                          key={"professional_groups"}
                        />
                        <InfoListWithExtras
                          listItems={member.sections.memberships}
                          listTitle={translate("directory.member.info.label.section")}
                          extras={member.sections.roles}
                          onTagClick={callbackFactory("sections")}
                          type={"quaternary"}
                          key={"sections"}
                        />
                        <InfoList
                          listItems={member.commissions}
                          listTitle={translate("directory.member.info.label.commission")}
                          onTagClick={callbackFactory("sia_commissions")}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              {member.iscompany && member.self_description && (
                <div className="bx--row sia-padding-03-top">
                  <div className="bx--col-lg-10 bx--col-md-8 bx--col-sm-4 CompanyPage-Col">
                    <div className="sia-padding-02-right">
                      <ExtraInfo infos={member.self_description} title={translate("directory.member.info.label.further-info")} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {member.is_sia_specialist && (
            <div className="bx--row sia-padding-05-top">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <div className="sia-padding-02-bottom sia-border-01-bottom sia-text-03">{translate("directory.filter.expert.label")}</div>
              </div>
              <div className="sia-padding-02-top bx--offset-lg-4 bx--col-lg-5 bx--col-md-4 bx--col-sm-4">
                <div className="sia-padding-02-right">
                  {member.specialisation_details.phone && (
                    <Address
                      phone={member.specialisation_details.phone}
                      mobile={member.specialisation_details.mobile}
                      title={translate("directory.member.info.expert.label.telephone")}
                    />
                  )}
                  {member.specialisation_details.email && (
                    <Address email={member.specialisation_details.email} title={translate("directory.member.info.expert.label.email")} />
                  )}
                  {member.specialisation_details.website && (
                    <Address website={member.specialisation_details.website} title={translate("directory.member.info.expert.label.website")} />
                  )}
                </div>
              </div>
              <div id="Specialis_RightCol" className="sia-padding-02-top bx--col-lg-7 bx--col-md-4 bx--col-sm-4">
                <div className="sia-padding-02-right">
                  <div>
                    <FragmentTitle title={translate("directory.member.info.expert.label.specialisations")} />
                    {member.specialisation_details.specialisations.map((e, idx) => (
                      <Tag type={"tertiary"} label={e} key={idx} />
                    ))}
                  </div>
                  <div className="sia-padding-02-top">
                    <FragmentTitle title={translate("directory.member.info.expert.label.languages")} />
                    {member.specialisation_details.specialist_languages.map((e, idx) => (
                      <Tag type={"tertiary"} label={e} key={idx} />
                    ))}
                  </div>
                  <div className="sia-padding-02-top">
                    <FragmentTitle title={translate("directory.member.info.expert.label.statement")} />
                    <p>{member.specialisation_details.statment}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {member.iscompany && (
            <div className="bx--grid reset-spacings">
              {member.reference_objects.length > 0 && (
                <div className="bx--row CompanyPage-Reference-Row">
                  <div className="bx--col--16">
                    <p>{translate("directory.member.related.reference-objects")}</p>
                  </div>
                  <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                    <ReferenceGalleryItemPreviews items={member.reference_objects} translate={translate} elementToRender={RouterLink} wideLines />
                  </div>
                </div>
              )}
              {member.jobs.length > 0 && (
                <div className="bx--row CompanyPage-Reference-Row">
                  <div className="bx--col--16">
                    <p>{translate("directory.member.related.jobs")}</p>
                  </div>
                  <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                    <JobPreviews items={member.jobs} elementToRender={RouterLink} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Content>
    </Page>
  );
};

export default MemberDetailsPage;
