import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { SIAPage, SIAEventSpeaker, SIAEventOccurrence, Logo } from "@sia/style-guide";
import cmsAdapter from "../../common/Adapters/CMSAdapter/CMSAdapter.js";
import { scrollToElement } from "../CMSContent/scrollToElement.js";
import { getNode } from "../CMSContent/getNode.js";
import { useTranslator } from "../../common/Translation/Translator.js";
import useContentMethods from "../CMSContent/useContentMethods.js";
import useFormMethods from "../CMSContent/useFormMethods.js";
import Page from "pages/Page";
import GlobalContext from "../../contexts/GlobalContext.js";
import "./AgendaPages.scss";

const PageContent = ({ languageKey, nodeId, isHash, menuItems, noMenuPages, isEventOccurrence, translate }) => {
  const history = useHistory();
  const alias = history.location.pathname.replace(`/${languageKey}/agenda/speaker/`, "").replace(`/${languageKey}/agenda/`, "");
  const [isLoading, setIsLoading] = useState(true);
  const [siaContentProps, setSiaContentProps] = useState({});
  const [cachedValues, setCachedValues] = useState({});
  const [contentType, setContentType] = useState("");

  const node_id = React.useMemo(() => {
    return nodeId || getNode({ alias, history, languageKey, menuItems, noMenuPages });
  }, [nodeId, alias, history, languageKey, menuItems, noMenuPages]);

  const { getContentByHash, getContentByNodeID } = useContentMethods(
    cmsAdapter,
    cachedValues,
    setCachedValues,
    languageKey,
    history,
    setContentType,
    setSiaContentProps,
    setIsLoading,
    node_id,
    alias,
    isEventOccurrence
  );
  const { fetchForm, onSubmitForm, fetchTerms } = useFormMethods(cmsAdapter, cachedValues, setCachedValues);
  const getParagraph = (onSuccess, languageKey, id) => {
    cmsAdapter.fetchParagraph(onSuccess, languageKey, id);
  };

  const loadPage = () => {
    setIsLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (isHash) return getContentByHash();
    if (node_id > 0 || isEventOccurrence) return getContentByNodeID();
    else if (node_id !== -1) history.push(`/not-found`);
  };

  useEffect(() => {
    !isLoading && scrollToElement(history);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    (alias || nodeId) && loadPage();
    // eslint-disable-next-line
  }, [alias, nodeId]);

  useEffect(() => {
    const createBrowserTitle = () => {
      const browserTitle = translate("browser.title");
      if (siaContentProps.title) {
        return `${siaContentProps.title} - ${browserTitle}`;
      }
      return browserTitle;
    };
    document.title = createBrowserTitle();
  }, [siaContentProps.title, translate]);
  return (
    !isLoading && (
      <div className="agenda_page">
        {contentType === "sia_page" && (
          <>
            <div className="SIAInform-logo">
              <Logo className="inform" logoType="inform" />
            </div>
            <SIAPage
              translate={(value, options) => translate(value, options)}
              key={siaContentProps?.title}
              {...siaContentProps}
              lang={languageKey}
              history={history}
              inFormMethods={{
                fetchForm,
                onSubmitForm,
                fetchTerms,
                fetchNode: (languageKey, alias, onSuccess) => cmsAdapter.fetchEvent(languageKey, alias, onSuccess),
              }}
              captchaKey={process.env.REACT_APP_CAPTCHA_KEY}
              getParagraph={getParagraph}
            />
          </>
        )}
        {contentType === "speaker" && (
          <SIAEventSpeaker speaker={siaContentProps} lang={languageKey} history={history} translate={(value, options) => translate(value, options)} />
        )}
        {contentType === "event_occurrence" && (
          <SIAEventOccurrence
            lang={languageKey}
            history={history}
            translate={(value, options) => translate(value, options)}
            eventCode={siaContentProps.item?.occurence_code}
            formatName={siaContentProps.item?.event_format}
            status={siaContentProps.item?.event_status}
            statusLabel={siaContentProps.item?.status_label}
            eventOrganisatorName={siaContentProps.item?.event_organisator}
            eventLocations={siaContentProps.item?.event_locations}
            eventLanguages={siaContentProps.item?.languages}
            eventTitle={siaContentProps.item?.title}
            eventLead={siaContentProps.item?.event_lead}
            eventSublead={siaContentProps.item?.event_sublead}
            eventPrice={siaContentProps.item?.event_price}
            eventPageBottom={siaContentProps.item?.event_content}
            partners={siaContentProps.item?.partners}
            media={siaContentProps.item?.media}
            speakers={siaContentProps.item?.speaker}
            dateTimes={siaContentProps.item?.date_times}
            eventOccurrences={siaContentProps.item?.event_occurrences}
            relatedEvents={siaContentProps.item?.related_events}
          />
        )}
      </div>
    )
  );
};

const AgendaAppPage = ({ nodeId, isHash, isLoadingPages, isEventOccurrence }) => {
  const { language: languageKey, menuItems, noMenuPages } = useContext(GlobalContext);
  const translate = useTranslator();

  return (
    <div className="cms-content">
      {languageKey && !isLoadingPages && (
        <Page title={translate("header.app-switcher.agenda.name")}>
          <PageContent
            nodeId={nodeId}
            isHash={isHash}
            languageKey={languageKey}
            menuItems={menuItems}
            noMenuPages={noMenuPages}
            isEventOccurrence={isEventOccurrence}
            translate={translate}
          />
        </Page>
      )}
    </div>
  );
};

export default AgendaAppPage;
