import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tiles } from "@sia/style-guide/dist/components";
import { useTranslator } from "common/Translation/Translator";
import { Link } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import './CustomerDataPage.scss';
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import { Content, Tile } from "@sia/style-guide";
import CustomerDataTile from "./CustomerDataTile";

const CustomerDataPage = () => {
    const translate = useTranslator();
    const pathFactory = usePathFactory();
    const tileColumnClassName = 'bx--col-lg-8 bx--col-md-4 bx--col-sm-4';
    const [tileRows, setTileRows] = useState([]);
    const [wasTilesUpdateStarted, setWasTilesUpdateStarted] = useState(false);
    const GlobalContextValue = useContext(GlobalContext);
    const user = GlobalContextValue.user;
    const tiles = useMemo(() => {


        const initialTiles = [
            new CustomerDataTile(
                'account.customer-data.login-information',
                'account.customer-data.login-information.description',
                pathFactory.create('account/customer-data/login-information'),
                ['single', 'corporate'],
            ),
            new CustomerDataTile(
                'account.customer-data.base-data.single-person',
                'account.customer-data.base-data.description.single-person',
                pathFactory.create('account/customer-data/personal-data'),
                ['single'],
            ),
            new CustomerDataTile(
                'account.customer-data.base-data.corporate',
                'account.customer-data.base-data.description.corporate',
                pathFactory.create('account/customer-data/base-data'),
                ['corporate'],
            ),
            new CustomerDataTile(
                'account.customer-data.addresses',
                'account.customer-data.addresses.description',
                pathFactory.create('account/customer-data/addresses'),
                ['single', 'corporate'],
            ),
            new CustomerDataTile(
                'account.customer-data.membership',
                'account.customer-data.membership.description.single-person',
                pathFactory.create('account/customer-data/memberships'),
                ['single'],
            ),
            new CustomerDataTile(
                'account.customer-data.membership',
                'account.customer-data.membership.description.corporate',
                pathFactory.create('account/customer-data/memberships'),
                ['corporate'],
            ),
            new CustomerDataTile(
                'account.customer-data.formation',
                'account.customer-data.formation.description',
                pathFactory.create('account/customer-data/formation'),
                ['single'],
            )]
        if (user && user.hasSubsidiaries) {
            const subsidiaryTile = new CustomerDataTile(
                'account.customer-data.addresses.subsidiary',
                'account.customer-data.addresses.subsidiary.description',
                pathFactory.create('account/customer-data/addresses/subsidiary'),
                ['corporate'],
            );
            initialTiles.splice(4, 0, subsidiaryTile)
        }
        if (user) {
            const directoryCorporate = new CustomerDataTile(
                'account.customer-data.sia-directory',
                'account.customer-data.sia-directory.description.corporate',
                pathFactory.create('account/customer-data/directory'),
                ['corporate'],
            )
            const directorySingle = new CustomerDataTile(
                'account.customer-data.sia-directory',
                'account.customer-data.sia-directory.description.single-person',
                pathFactory.create('account/customer-data/directory'),
                ['single'],
            )
            if (user.isMemberTypeGroupOf('corporate')) {
                initialTiles.push(directoryCorporate)
                const NO_MEMBER_CORPORATE_TYPE_ID = 50
                if(user.memberType.id === NO_MEMBER_CORPORATE_TYPE_ID){
                    initialTiles.splice(5,1)
                    initialTiles.splice(6,1)
                }
            }
            else {
                initialTiles.push(directorySingle)
            }
        }
        return initialTiles
    } , [pathFactory, user]);


    const createTileRows = useCallback(() => {
        const memberTypeTiles = tiles.filter(tile => tile.getMemberTypeGroups().includes(user.getMemberType().getGroup()));
        const rows = [];
        const itemsPerRow = 2;
        memberTypeTiles.forEach(tile => {
            const lastRowIndex = rows.length - 1;
            const lastRow = rows[lastRowIndex];
            const needsNewRow = !lastRow || (lastRow.length === itemsPerRow);
            if (needsNewRow) {
                const row = [];
                row.push(tile);
                rows.push(row);
                return;
            }
            rows[lastRowIndex].push(tile);
        })
        return rows;
    }, [tiles, user])

    useEffect(() => {
        if (user && user.getMemberType() && !wasTilesUpdateStarted) {
            setWasTilesUpdateStarted(true);
            setTileRows(createTileRows());
        }
    }, [user, wasTilesUpdateStarted, pathFactory, createTileRows])

    const renderTileRowCells = (row) => {
        return row.map(tile => {
            const link = <Link to={tile.getLink()}>{translate('account.customer-data.manage.label')}</Link>
            return <div className={tileColumnClassName} key={tile.getTitle()}>
                <Tile title={translate(tile.getTitle())} button={link}>
                    <p>{translate(tile.getContent())}</p>
                </Tile>
            </div>
        });
    }
    const renderTileRows = () => {
        return tileRows.map((row, rowIndex) => {
            const renderedCells = renderTileRowCells(row);
            return <div className="bx--row" key={rowIndex}>{renderedCells}</div>;
        })
    }

    return <Page contentNavigation={<UserContentNavigation />} title={'account.name'}>
        <Content className={'customer-data'} centeredTexts={true}>
            <p>
                {translate('account.customer-data.description.information')}<br />
                {translate('account.customer-data.description.data')}
            </p>
            <Tiles>
                {renderTileRows()}
            </Tiles>
        </Content>
    </Page>;
}

export default CustomerDataPage;
