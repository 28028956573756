import React, { useContext } from "react";
import GlobalContext from "contexts/GlobalContext";
import CMSContentWrapper from "./CMSContentWrapper";
import "./CMSContent.scss";
const CMSContent = ({ nodeId, isHash }) => {
  const { language: languageKey, menuItems, noMenuPages } = useContext(GlobalContext);
  return (
    <div className="cms-content">
      {languageKey && <CMSContentWrapper nodeId={nodeId} isHash={isHash} languageKey={languageKey} menuItems={menuItems} noMenuPages={noMenuPages} />}
    </div>
  );
};

export default CMSContent;
